<template>
  <div class="content">
    <div class="content-left">
      <MapPanel
        ref="mapPanel2"
        id="mapContainerTraffic"
        :legendsObj="legendsObj"
        :ignoreFitChina="true"
        @loaded="handleMapLoaded"
        @click="handleMapClick"
      ></MapPanel>
    </div>
    <div class="content-right" v-loading="loading">
      <CustomData :dataList="dataList" :rowNumber="2"></CustomData>
    </div>
  </div>
</template>
<script>
import MapPanel from "@/components/MapPanel.vue";
import CustomData from "@/components/CustomData.vue";
import { GetCommonInfo, SearchData } from "@/service";
import InfoWindowBrand from "@/components/InfoWindowBrand.vue";
import Vue from "vue";
import * as turf from "@turf/turf";
import { openNewWindow } from "js/utils.js";
import { wkb2geom } from "js/map/map.utils.js";
export default {
  props: ["detailId"],
  data() {
    return {
      mapPanel2: null,
      isMapLoaded: false,
      legends: [
        {
          title: "公交站",
          key: "bus",
          type: "marker",
          show: false,
        },
        {
          title: "地铁站",
          key: "subway",
          type: "marker",
          show: false,
        },
        {
          title: "火车站",
          key: "train",
          type: "marker",
          show: true,
        },
        {
          title: "机场",
          key: "airport",
          type: "marker",
          show: true,
        },
      ],
      trafficFields: [
        "公交线路",
        "公交车站数量",
        "地铁线路",
        "地铁站数量",
        "火车站",
        "机场",
      ],
      dataList: [
        { title: "公交线路", value: 0, unit: "条" },
        { title: "公交站", value: 0, unit: "个" },
        { title: "地铁线路", value: 0, unit: "条" },
        { title: "地铁站", value: 0, unit: "个" },
        { title: "火车站", value: 0, unit: "个" },
        { title: "机场", value: 0, unit: "个" },
      ],
      filterCondition: [],
      loading: false,
    };
  },
  computed: {
    legendsObj() {
      return {
        list: this.legends,
      };
    },
  },
  watch: {
    detailId() {
      // this.initData();
    },
  },
  methods: {
    handleMapLoaded() {
      this.isMapLoaded = true;
      this.initData();
    },
    async initData() {
      this.loading = true;
      await this.getDetailData();
      await this.queryLegendsPois();
      this.loading = false;
    },
    async getDetailData() {
      let params = {
        identify: "sg-data-商圈-城市概况-城市交通",
        fields: this.trafficFields,
        filters: [
          {
            field: "field1",
            cond: 0,
            value: [this.detailId],
          },
        ],
        sort: [],
        pages: {
          page: 1,
          size: 10,
        },
      };
      let res = await GetCommonInfo(params);
      if (res && res.code === 200) {
        let items = res.data.items || [];
        let item = items[0];
        this.dataList[0].value = item[0] || item[0] === 0 ? item[0] : "";
        this.dataList[1].value = item[1] || item[1] === 0 ? item[1] : "";
        this.dataList[2].value = item[2] || item[2] === 0 ? item[2] : "";
        this.dataList[3].value = item[3] || item[3] === 0 ? item[3] : "";
        this.dataList[4].value = item[4] || item[4] === 0 ? item[4] : "";
        this.dataList[5].value = item[5] || item[5] === 0 ? item[5] : "";
      }
    },
    queryLegendsPois() {
      for (let i = 0, n = this.legends.length; i < n; i++) {
        this.queryAreaPois(i);
      }
    },
    async queryAreaPois(legendIndex) {
      let title = this.legends[legendIndex].title;
      let fields = ["站点类别", "站点名称", "地址", "geom"];
      let params = {
        identify: "sg-data-城市-机场火车站公交站地铁详情",
        fields: fields,
        filters: [
          {
            field: "key1",
            cond: 0,
            value: [this.detailId],
          },
          {
            field: "站点类别",
            cond: 0,
            value: [title],
          },
        ],
        // pages: {
        //   page: 1,
        //   size: 10000
        // }
      };
      let res = await GetCommonInfo(params);
      if (res && res.code === 200) {
        let list = res.data.items || [];
        let features = [];
        for (let i = 0, n = list.length; i < n; i++) {
          let item = list[i];
          let attrs = [];
          item.attrs = attrs;
          item.isPoi = true;
          let geom = wkb2geom(item[3]);
          let feature = {
            type: "Feature",
            properties: item,
            geometry: geom,
          };
          features.push(feature);
        }
        let options = {
          icon: "/images/marker_blue2.png",
          iconSize: [22, 33],
          anchor: "bottom-center",
        };
        this.mapPanel2.setLegendPolygon(legendIndex, features, options);
        // if (title.includes("地铁站")) {
        this.mapPanel2.fitBounds(features, false, [10, 10, 100, 100]);
        // }
      }
    },

    getFeatureByItem(item) {
      let geom = item.geom;
      if (typeof geom === "string") {
        geom = JSON.parse(geom);
      }
      let feature = {
        type: "Feature",
        geometry: geom,
        properties: item,
      };
      return feature;
    },
    // 点位点击
    handleMapClick(feature) {
      this.openWindowInfo(feature);
    },
    openWindowInfo(feature) {
      let properties = feature.properties;
      let center = feature.geometry.coordinates;
      let InfoWindow = Vue.extend(InfoWindowBrand);
      let instance = new InfoWindow({
        propsData: {
          title: properties[1],
          list: [`地址/线路：${properties[2] || "--"} `],
        },
      });
      instance.$on("doSubmit", () => {});
      instance.$mount();
      let content = instance.$el;
      this.mapPanel2.openInfoWindow(center, content, {
        offset: [0, -23],
        autoMove: false,
      });
    },
  },
  mounted() {
    this.mapPanel2 = this.$refs.mapPanel2;
  },
  components: {
    MapPanel,
    CustomData,
  },
};
</script>
<style lang="less">
.content {
  .content-left {
    width: calc(100% - 420px);
    height: 100%;
  }
  .content-right {
    width: 420px;
    height: 100%;
    padding: 30px;
    position: relative;
  }
}
</style>
