<template>
  <div class="content">
    <div class="content-left">
      <MapPanel
        ref="mapPanel"
        :legendsObj="legendsObj"
        :ignoreFitChina="true"
        @loaded="handleMapLoaded"
        @click="handleMapClick"
      ></MapPanel>
    </div>
    <div class="content-right" v-loading="loading">
      <CustomData :dataList="dataList" :rowNumber="2"></CustomData>
    </div>
  </div>
</template>
<script>
import MapPanel from "@/components/MapPanel.vue";
import CustomData from "@/components/CustomData.vue";
import { GetCommonInfo, SearchData, GetDetailBrowsingRecord } from "@/service";
import InfoWindowBrand from "@/components/InfoWindowBrand.vue";
import Vue from "vue";
import * as turf from "@turf/turf";
import { openNewWindow } from "js/utils.js";
export default {
  props: ["detailId"],
  data() {
    return {
      mapPanel: null,
      isMapLoaded: false,
      legends: [
        {
          title: "商圈",
          key: "business",
          type: "polygons",
        },
        {
          title: "购物中心",
          key: "mall",
          type: "marker",
        },
      ],
      businessFields: [
        "商圈总数量",
        "购物中心数量",
        "购物中心商业面积",
        "人均购物中心面积",
      ],
      dataList: [
        { title: "商圈总数量", value: -1, unit: "个" },
        { title: "购物中心数量", value: -1, unit: "个" },
        { title: "购物中心商业面积", value: -1, unit: "m²" },
        { title: "人均购物中心面积", value: -1, unit: "m²" },
      ],
      filterCondition: [],
      loading: false,
    };
  },
  computed: {
    legendsObj() {
      return {
        list: this.legends,
      };
    },
  },
  watch: {
    detailId() {
      // this.initData();
    },
  },
  methods: {
    handleMapLoaded() {
      this.isMapLoaded = true;
      this.initData();
    },
    async initData() {
      this.loading = true;
      await this.getDetailData();
      await this.getBusinessGeom();
      await this.getMallGeom();
      this.loading = false;
    },
    async getDetailData() {
      let params = {
        identify: "sg-data-商圈-城市概况-商业情况",
        fields: this.businessFields,
        filters: [
          {
            field: "key1",
            cond: 0,
            value: [this.detailId],
          },
        ],
        sort: [],
        pages: {
          page: 1,
          size: 10,
        },
      };
      let res = await GetCommonInfo(params);
      if (res && res.code === 200) {
        let items = res.data.items || [];
        let item = items[0];
        this.dataList[0].value = item[0] || item[0] === 0 ? item[0] : "";
        this.dataList[1].value = item[1] || item[1] === 0 ? item[1] : "";
        this.dataList[2].value = item[2] || item[2] === 0 ? item[2] : "";
        this.dataList[3].value = item[3] || item[3] === 0 ? item[3] : "";
      }
    },
    async getBusinessGeom() {
      this.filterCondition = [
        {
          field: "cityCode",
          condition: [this.detailId],
        },
      ];
      let params = {
        searchSort: 4,
        word: "",
        page: this.page || 1,
        pageSize: 10000,
        filterCondition: this.filterCondition || [],
        orderCondition: {},
        lat: "",
        lon: "",
      };

      let res = await SearchData(params);
      if (res && res.code === 200) {
        let list = res.data.items || [];
        this.getMapPolygon(list);
      }

      this.isLoading = false;
    },
    // 绘制面
    getMapPolygon(list) {
      if (!list.length || !this.isMapLoaded) return;
      let features = [];
      list.forEach((item) => {
        let geom = JSON.parse(item.geom);
        let feature = {
          type: "Feature",
          geometry: geom,
          properties: {
            name: item.name,
            id: item.code,
            level: item.level,
            type: item.type,
            childrenNumber: item.childrenNumber,
          },
        };
        features.push(feature);
      });
      let options = {
        strokeColor: "#3519FB",
        strokeOpacity: 0.6,
        strokeWeight: 2,
        fillColor: "#3519FB",
        fillOpacity: 0.2,
        strokeStyle: "solid",
      };
      this.mapPanel.fitBounds(features, false, [100, 100, 100, 100]);
      this.mapPanel.setLegendPolygon(0, features, options);
    },
    async getMallGeom() {
      let params = {
        searchSort: 3,
        word: "",
        page: 1,
        pageSize: 10000,
        filterCondition: [
          {
            field: "cityCode",
            condition: [this.detailId],
          },
        ],
        orderCondition: {},
        lat: "",
        lon: "",
      };
      this.isLoading = true;
      let res = await SearchData(params);
      if (res && res.code === 200) {
        let list = res.data.items || [];
        this.getMapMarker(list);
        this.mallList = list;
      }
    },
    getMapMarker(list) {
      let features = [];
      for (let i = 0, n = list.length; i < n; i++) {
        let item = list[i];
        let feature = this.getFeatureByItem(item);
        features.push(feature);
      }
      let options = {
        icon: "/images/marker_blue2.png",
        iconSize: [22, 33],
        anchor: "bottom-center",
      };
      this.mapPanel.setLegendPolygon(1, features, options);
    },
    getFeatureByItem(item) {
      let geom = item.geom;
      if (typeof geom === "string") {
        geom = JSON.parse(geom);
      }
      let feature = {
        type: "Feature",
        geometry: geom,
        properties: item,
      };
      return feature;
    },
    // 商圈点击
    handleMapClick(feature) {
      let properties = feature.properties;
      if (
        properties.indexType &&
        properties.indexType === "data_observation_mall"
      ) {
        this.openWindowInfoMall(feature);
        return;
      }
      this.openWindowInfo(feature);
    },
    openWindowInfoMall(feature) {
      let properties = feature.properties;
      let center = feature.geometry.coordinates;
      let InfoWindow = Vue.extend(InfoWindowBrand);
      let instance = new InfoWindow({
        propsData: {
          title: properties["name"],
          list: [
            `地址: ${properties["addr"]}`,
            `开业时间: ${properties["startTime"]}`,
          ],
          btnText: "查看详情",
        },
      });
      instance.$on("doSubmit", () => {
        let code = properties.code;
        this.gotoMallDetail(code);
      });
      instance.$mount();
      let content = instance.$el;
      this.mapPanel.openInfoWindow(center, content, {
        offset: [0, -23],
        autoMove: false,
      });
    },
    async gotoMallDetail(code) {
      const res = await GetDetailBrowsingRecord({
        module: "mallDetail",
        detailID: code,
      });
      if (res && res.code == 200 && res.data == "success") {
        openNewWindow("/mallDetail", {
          id: code,
        });
      }
    },
    openWindowInfo(feature) {
      let properties = feature.properties;
      let polygon = turf.polygon(feature.geometry.coordinates);
      let center = turf.centerOfMass(polygon);
      let coordinates = center.geometry.coordinates;
      let InfoWindow = Vue.extend(InfoWindowBrand);
      let instance = new InfoWindow({
        propsData: {
          title: properties.name,
          list: [
            `商圈级别: ${properties.level}`,
            `商圈等级: ${properties.type}`,
            `商圈内有: ${properties.childrenNumber}个商业项目`,
          ],
          btnText: "查看详情",
        },
      });
      instance.$on("doSubmit", async () => {
        const res = await GetDetailBrowsingRecord({
          module: "businessDetail",
          detailID: properties.id,
        });
        if (res && res.code == 200 && res.data == "success") {
          openNewWindow("/businessDetail", {
            id: properties.id,
          });
        }
      });
      instance.$mount();
      let content = instance.$el;
      this.mapPanel.openInfoWindow(coordinates, content, {
        offset: [0, -23],
      });
    },
  },
  mounted() {
    // this.initData();
    this.mapPanel = this.$refs.mapPanel;
  },
  components: {
    MapPanel,
    CustomData,
  },
};
</script>
<style lang="less">
.content {
  .content-left {
    width: calc(100% - 420px);
    height: 100%;
  }
  .content-right {
    width: 420px;
    height: 100%;
    padding: 30px;
    position: relative;
  }
}
</style>
