var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "site-wrap detail-main" },
    [
      _c("Header", {
        attrs: { mode: 2, detailType: "城市", searchKey: _vm.searchKey },
      }),
      _c("MenuSide", {
        attrs: { list: _vm.menuList, activeMenu: _vm.activeMenu },
        on: { isOpen: _vm.isMenuOpen, onselect: _vm.menuSelect },
      }),
      _c(
        "div",
        {
          ref: "scroll",
          staticClass: "main-scroll",
          class: _vm.menuOpen ? "" : "main-scroll-change",
        },
        [
          _c(
            "div",
            { staticClass: "map-size" },
            [
              _vm.mapPoints.length != 0
                ? _c("MapPanelLittle", {
                    attrs: { id: 1, markers: _vm.mapPoints },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "main-size" },
            [
              _c("SummaryCard", {
                attrs: {
                  cardType: 5,
                  cardInfo: _vm.detailInfo,
                  isVip: false,
                  collectShow: true,
                  claimShow: false,
                  shareShow: true,
                  detailID: _vm.cityID,
                },
              }),
              _c(
                "div",
                { staticClass: "content-item", attrs: { id: "zonghe" } },
                [
                  _c("div", { staticClass: "item-title" }, [
                    _vm._v("综合经济"),
                  ]),
                  _vm.economyTime && _vm.detailInfo
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.zongheLoadingStatus,
                              expression: "zongheLoadingStatus",
                            },
                          ],
                          staticClass: "economy-size",
                        },
                        [
                          _c("div", { staticClass: "economy-nav" }, [
                            _c(
                              "div",
                              {
                                staticClass: "economy-nav-box",
                                class: _vm.economyID === 1 ? "nav-choose" : "",
                                on: {
                                  click: function ($event) {
                                    return _vm.economyChange(1)
                                  },
                                },
                              },
                              [_vm._v(" GDP ")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "economy-nav-box",
                                class: _vm.economyID === 2 ? "nav-choose" : "",
                                on: {
                                  click: function ($event) {
                                    return _vm.economyChange(2)
                                  },
                                },
                              },
                              [_vm._v(" 人均可支配收入 ")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "economy-nav-box",
                                class: _vm.economyID === 3 ? "nav-choose" : "",
                                on: {
                                  click: function ($event) {
                                    return _vm.economyChange(3)
                                  },
                                },
                              },
                              [_vm._v(" 人均消费支出 ")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "economy-nav-box",
                                class: _vm.economyID === 4 ? "nav-choose" : "",
                                on: {
                                  click: function ($event) {
                                    return _vm.economyChange(4)
                                  },
                                },
                              },
                              [_vm._v(" 消费品零售总额 ")]
                            ),
                          ]),
                          _vm.economyID === 1
                            ? _c("div", { staticClass: "charts-size" }, [
                                _c("div", { staticClass: "charts-title" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.economyTime[0]) +
                                      "-" +
                                      _vm._s(_vm.economyTime[1]) +
                                      _vm._s(_vm.detailInfo["城市名称"]) +
                                      "GDP（亿元） "
                                  ),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "charts-box" },
                                  [
                                    _vm.echartData.data1.length != 0
                                      ? _c("BarLineChart", {
                                          attrs: {
                                            echartData: _vm.echartData,
                                            barColor: _vm.barColor,
                                          },
                                        })
                                      : _c("DataNull"),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _vm.economyID === 2
                            ? _c("div", { staticClass: "charts-size" }, [
                                _c("div", { staticClass: "charts-title" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.economyTime2[0]) +
                                      "-" +
                                      _vm._s(_vm.economyTime2[1]) +
                                      _vm._s(_vm.detailInfo["城市名称"]) +
                                      "人均可支配收入（元） "
                                  ),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "charts-box" },
                                  [
                                    _vm.echartsData2.length != 0
                                      ? _c("BarChart", {
                                          attrs: {
                                            echartData: _vm.echartsData2,
                                            barWidth: 28,
                                            barColor: _vm.barColorCon,
                                            textColor: _vm.textColorCon,
                                            yUnit: "",
                                            echartSize: _vm.echartSizeCon,
                                            maxLength: 8,
                                            isHideY: true,
                                            isHideYline: true,
                                          },
                                        })
                                      : _c("DataNull"),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _vm.economyID === 3
                            ? _c("div", { staticClass: "charts-size" }, [
                                _c("div", { staticClass: "charts-title" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.economyTime3[0]) +
                                      "-" +
                                      _vm._s(_vm.economyTime3[1]) +
                                      _vm._s(_vm.detailInfo["城市名称"]) +
                                      "人均消费支出（元） "
                                  ),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "charts-box" },
                                  [
                                    _vm.echartsData3.length != 0
                                      ? _c("BarChart", {
                                          attrs: {
                                            echartData: _vm.echartsData3,
                                            barWidth: 28,
                                            barColor: _vm.barColorCon,
                                            textColor: _vm.textColorCon,
                                            yUnit: "",
                                            echartSize: _vm.echartSizeCon,
                                            maxLength: 8,
                                            isHideY: true,
                                            isHideYline: true,
                                          },
                                        })
                                      : _c("DataNull"),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _vm.economyID === 4
                            ? _c("div", { staticClass: "charts-size" }, [
                                _c("div", { staticClass: "charts-title" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.economyTime4[0]) +
                                      "-" +
                                      _vm._s(_vm.economyTime4[1]) +
                                      _vm._s(_vm.detailInfo["城市名称"]) +
                                      "消费品零售总额（亿元） "
                                  ),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "charts-box" },
                                  [
                                    _vm.echartsData4.length != 0
                                      ? _c("BarChart", {
                                          attrs: {
                                            echartData: _vm.echartsData4,
                                            barWidth: 28,
                                            barColor: _vm.barColorCon,
                                            textColor: _vm.textColorCon,
                                            yUnit: "",
                                            echartSize: _vm.echartSizeCon,
                                            maxLength: 8,
                                            isHideY: true,
                                            isHideYline: true,
                                          },
                                        })
                                      : _c("DataNull"),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
              _c(
                "div",
                { staticClass: "content-item", attrs: { id: "shangye" } },
                [
                  _c("div", { staticClass: "item-title" }, [
                    _vm._v("商业数据"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "content-wrap" },
                    [_c("Business", { attrs: { detailId: _vm.cityID } })],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "content-item", attrs: { id: "jiaotong" } },
                [
                  _c("div", { staticClass: "item-title" }, [
                    _vm._v("交通数据"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "content-wrap" },
                    [_c("Traffic", { attrs: { detailId: _vm.cityID } })],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "demo-item custom-item",
                  attrs: { id: "depth" },
                },
                [
                  _c(
                    "div",
                    {
                      ref: "offlineAnalysis",
                      staticClass: "anchor-box8",
                      attrs: { id: "offlineAnalysis" },
                    },
                    [_c("offline-analysis")],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "anchor-box9",
                      attrs: { id: "onlineAnalysis" },
                    },
                    [_c("online-analysis")],
                    1
                  ),
                ]
              ),
              _c("div", { staticClass: "page-bottom" }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }