<template>
  <div class="site-wrap detail-main">
    <Header :mode="2" detailType="城市" :searchKey="searchKey"></Header>
    <MenuSide
      :list="menuList"
      :activeMenu="activeMenu"
      @isOpen="isMenuOpen"
      @onselect="menuSelect"
    ></MenuSide>
    <div
      class="main-scroll"
      ref="scroll"
      :class="menuOpen ? '' : 'main-scroll-change'"
    >
      <div class="map-size">
        <MapPanelLittle
          :id="1"
          :markers="mapPoints"
          v-if="mapPoints.length != 0"
        ></MapPanelLittle>
      </div>
      <div class="main-size">
        <!--isVip：是否为VIP身份；collectShow：是否显示收藏按钮；claimShow：是否显示认领按钮；shareShow：是否显示分享按钮；-->
        <SummaryCard
          :cardType="5"
          :cardInfo="detailInfo"
          :isVip="false"
          :collectShow="true"
          :claimShow="false"
          :shareShow="true"
          :detailID="cityID"
        ></SummaryCard>
        <!--综合经济-->
        <div class="content-item" id="zonghe">
          <div class="item-title">综合经济</div>
          <div
            class="economy-size"
            v-if="economyTime && detailInfo"
            v-loading="zongheLoadingStatus"
          >
            <div class="economy-nav">
              <div
                class="economy-nav-box"
                :class="economyID === 1 ? 'nav-choose' : ''"
                @click="economyChange(1)"
              >
                GDP
              </div>
              <div
                class="economy-nav-box"
                :class="economyID === 2 ? 'nav-choose' : ''"
                @click="economyChange(2)"
              >
                人均可支配收入
              </div>
              <div
                class="economy-nav-box"
                :class="economyID === 3 ? 'nav-choose' : ''"
                @click="economyChange(3)"
              >
                人均消费支出
              </div>
              <div
                class="economy-nav-box"
                :class="economyID === 4 ? 'nav-choose' : ''"
                @click="economyChange(4)"
              >
                消费品零售总额
              </div>
            </div>
            <!--GDP-->
            <div class="charts-size" v-if="economyID === 1">
              <div class="charts-title">
                {{ economyTime[0] }}-{{ economyTime[1]
                }}{{ detailInfo["城市名称"] }}GDP（亿元）
              </div>
              <div class="charts-box">
                <BarLineChart
                  :echartData="echartData"
                  :barColor="barColor"
                  v-if="echartData.data1.length != 0"
                ></BarLineChart>
                <DataNull v-else></DataNull>
              </div>
            </div>
            <!--人均可支配收入-->
            <div class="charts-size" v-if="economyID === 2">
              <div class="charts-title">
                {{ economyTime2[0] }}-{{ economyTime2[1]
                }}{{ detailInfo["城市名称"] }}人均可支配收入（元）
              </div>
              <div class="charts-box">
                <BarChart
                  :echartData="echartsData2"
                  :barWidth="28"
                  :barColor="barColorCon"
                  :textColor="textColorCon"
                  :yUnit="''"
                  :echartSize="echartSizeCon"
                  :maxLength="8"
                  :isHideY="true"
                  :isHideYline="true"
                  v-if="echartsData2.length != 0"
                >
                </BarChart>
                <DataNull v-else></DataNull>
              </div>
            </div>
            <!--人均消费支出-->
            <div class="charts-size" v-if="economyID === 3">
              <div class="charts-title">
                {{ economyTime3[0] }}-{{ economyTime3[1]
                }}{{ detailInfo["城市名称"] }}人均消费支出（元）
              </div>
              <div class="charts-box">
                <BarChart
                  :echartData="echartsData3"
                  :barWidth="28"
                  :barColor="barColorCon"
                  :textColor="textColorCon"
                  :yUnit="''"
                  :echartSize="echartSizeCon"
                  :maxLength="8"
                  :isHideY="true"
                  :isHideYline="true"
                  v-if="echartsData3.length != 0"
                >
                </BarChart>
                <DataNull v-else></DataNull>
              </div>
            </div>
            <!--消费品零售总额-->
            <div class="charts-size" v-if="economyID === 4">
              <div class="charts-title">
                {{ economyTime4[0] }}-{{ economyTime4[1]
                }}{{ detailInfo["城市名称"] }}消费品零售总额（亿元）
              </div>
              <div class="charts-box">
                <BarChart
                  :echartData="echartsData4"
                  :barWidth="28"
                  :barColor="barColorCon"
                  :textColor="textColorCon"
                  :yUnit="''"
                  :echartSize="echartSizeCon"
                  :maxLength="8"
                  :isHideY="true"
                  :isHideYline="true"
                  v-if="echartsData4.length != 0"
                >
                </BarChart>
                <DataNull v-else></DataNull>
              </div>
            </div>
          </div>
        </div>
        <!-- 商业数据 -->
        <div class="content-item" id="shangye">
          <div class="item-title">商业数据</div>
          <div class="content-wrap">
            <Business :detailId="cityID"></Business>
          </div>
        </div>
        <!-- 交通数据 -->
        <div class="content-item" id="jiaotong">
          <div class="item-title">交通数据</div>
          <div class="content-wrap">
            <Traffic :detailId="cityID"></Traffic>
          </div>
        </div>
        <div class="demo-item custom-item" id="depth">
          <div class="anchor-box8" ref="offlineAnalysis" id="offlineAnalysis">
            <!--demo图片，此div用于锚点定位-->
            <offline-analysis></offline-analysis>
          </div>
          <div class="anchor-box9" id="onlineAnalysis">
            <!--demo图片，此div用于锚点定位-->
            <online-analysis></online-analysis>
          </div>
        </div>
        <div class="page-bottom"></div>
      </div>
    </div>
  </div>
</template>

<script>
import SummaryCard from "../../components/SummaryCard.vue";
import MenuSide from "../../components/MenuSide.vue";
import { GetCommonInfo } from "@/service";
import { formatFieldsList } from "js/utils.js";
import { any2geom } from "js/map/map.utils.js";
import MapPanelLittle from "@/components/MapPanelLittle.vue";
import BarLineChart from "../../components/chart/barLineChart.vue";
import BarChart from "../../components/chart/barChart.vue";
import DataNull from "../../components/chart/dataNull.vue";
import Business from "../../components/city/Business.vue";
import Traffic from "../../components/city/Traffic.vue";

import offlineAnalysis from "../../components/depthAnalysis/offline.vue";
import onlineAnalysis from "../../components/depthAnalysis/online.vue";
export default {
  metaInfo() {
    let list = ["查城市"];
    if (!this.cityName) {
      return null;
    }
    list.unshift(this.cityName);
    return {
      title: list.join(" - "),
       meta: [
        {
          name: "keywords",
          content:
            `千里目,${this.cityName},城市数据,城市面积,户籍人口,常住人口,城市介绍,城市综合经济,城市GDP,城市人均可支配收入,城市人均消费支出,城市消费品零售总额,城市商业数据,城市商圈数量和分布,城市商场数量和分布,城市人均购物中心面积,城市交通数据,城市公交站和线路,城市地铁站和线路,城市火车站,城市机场`,
        },
        {
          name: "description",
          content:
            `${this.cityName}数据分析，包含城市面积、户籍人口、常住人口、城市介绍、城市GDP、城市人均可支配收入、城市人均消费支出、城市消费品零售总额、城市商圈数量和分布、城市商场数量和分布、城市人均购物中心面积、城市公交站和线路、城市地铁站和线路、城市火车站、城市机场等详细数据。`,
        },
       ]
    };
  },
  data() {
    return {
      menuOpen: true,
      cityID: "",
      detailInfo: [],
      cityName: "",
      //背景地图数据
      mapPoints: [],

      // 左侧菜单选中值
      activeMenu: "",
      // 左侧菜单调整自检名称，动态路由使用
      componentName: "",
      // 左侧菜单列表，key值临时定义使用时需修改
      menuList: [
        {
          key: "zonghe",
          name: "综合经济",
          title: "综合",
          isComponet: false,
        },
        {
          key: "shangye",
          name: "商业数据",
          title: "商业",
          isComponet: false,
        },
        {
          key: "jiaotong",
          name: "交通数据",
          title: "交通",
        },
        // {
        //   key: "深度分析",
        //   name: "深度分析",
        //   title: "深度",
        // },
        {
          key: "depth",
          name: "深度分析",
          title: "深度分析",
          children: [
            {
              key: "offlineAnalysis",
              name: "线下数据分析",
              icon: require("@/assets/images/icon/menu_xx.png"),
              isComponet: false,
            },
            {
              key: "onlineAnalysis",
              name: "线上营销分析",
              icon: require("@/assets/images/icon/menu_xs.png"),
              isComponet: false,
            },
          ],
        },
      ],

      economyID: 1,
      economyTime: [],
      echartData: {
        data1: [],
        data2: [],
        xUnit: [],
      },

      barColor: ["#3519FB", "#F99000"],
      barColorCon: "#3519FB",
      textColorCon: "#848295",
      echartSizeCon: { x: 0, x2: 0, y: 10, y2: 0, containLabel: true },
      economyTime2: [],
      echartsData2: [],
      economyTime3: [],
      echartsData3: [],
      economyTime4: [],
      echartsData4: [],
      zongheLoadingStatus: false,
      searchKey: "",
    };
  },
  computed: {
    scrollBox() {
      return this.$refs.scroll;
    },
  },
  methods: {
    isMenuOpen(isOpen) {
      this.menuOpen = isOpen;
    },
    menuSelect(name, isComponent) {
      if (isComponent) {
        // 线下营销或非锚点定位走动态路由
        this.componentName = name;
      } else {
        this.componentName = "";
        document.getElementById(name) &&
          document.getElementById(name).scrollIntoView({ behavior: "smooth" });
      }
    },
    //锚点和导航联动
    handleScroll() {
      if (this.componentName) return;
      //获取dom滚动距离
      const scrollTop = this.scrollBox.scrollTop;
      // 描点判断
      let oneTop = this.$el.querySelector("#zonghe").offsetTop;
      let twoTop = this.$el.querySelector("#shangye").offsetTop;
      let threeTop = this.$el.querySelector("#jiaotong").offsetTop;
      let sevenTop = this.$el.querySelector("#depth").offsetTop;
      let sevenOneTop = this.$el.querySelector("#offlineAnalysis").offsetTop;
      let sevenTwoTop = this.$el.querySelector("#onlineAnalysis").offsetTop;
      if (scrollTop >= oneTop && scrollTop < twoTop) {
        //滚动到达xxx
        this.activeMenu = "zonghe";
      } else if (scrollTop >= twoTop && scrollTop < threeTop) {
        //滚动到达
        this.activeMenu = "shangye";
      } else if (scrollTop >= threeTop && scrollTop < sevenTop) {
        //滚动到达
        this.activeMenu = "jiaotong";
      } else if (scrollTop >= sevenOneTop && scrollTop < sevenTwoTop) {
        this.activeMenu = "offlineAnalysis";
      } else if (scrollTop >= sevenTwoTop) {
        this.activeMenu = "onlineAnalysis";
      }
    },

    //获取详情信息（汇总卡片信息）
    async getDetail() {
      let params = {
        identify: "sg-data-城市-城市详情信息",
        filters: [
          {
            field: "城市编码",
            cond: 0,
            value: [this.cityID],
          },
        ],
        fields: [
          "城市名称",
          "城市级别",
          "城市线级",
          "城市简介",
          "行政区",
          "城市面积",
          "户籍人口",
          "常住人口",
          "城市图片",
          "面积排名",
          "户籍人口排名",
          "常住人口排名",
          "城市默认点位经度",
          "城市默认点位纬度",
          "城市默认点位位置",
          "城市面geom",
        ],
      };
      let res = await GetCommonInfo(params);
      if (res && res.code === 200) {
        let list = formatFieldsList(res.data || {});
        this.detailInfo = list[0] || [];
        this.cityName = this.detailInfo["城市名称"];
        //console.log(list,1111)

        //地图数据
        let polygon = any2geom(list[0]["城市面geom"] || "");
        let features = [];
        let feature = {
          type: "Feature",
          geometry: JSON.parse(JSON.stringify(polygon)),
        };
        features.push(feature);
        this.mapPoints = features;
      }
    },

    //综合经济
    async getEconomyData() {
      this.zongheLoadingStatus = true;
      let params = {
        identify: "sg-data-城市-综合经济",
        filters: [
          {
            field: "城市编码",
            cond: 0,
            value: [this.cityID],
          },
        ],
        fields: [
          "城市",
          "年份",
          "GDP",
          "人均可支配收入",
          "人均消费支出",
          "消费品零售总额",
          "GDP涨幅",
          "人均可支配收入涨幅",
          "人均消费支出涨幅",
          "消费品零售总额涨幅",
        ],
      };
      let res = await GetCommonInfo(params);
      if (res && res.code === 200) {
        let list = formatFieldsList(res.data || {});
        //数据按年份排序
        list.sort((a, b) => {
          return a["年份"] > b["年份"] ? 1 : -1;
        });

        //GDP数据整理
        for (let i in list) {
          this.echartData.data1.push(list[i]["GDP"] || "--");
          this.echartData.data2.push(list[i]["GDP涨幅"] || "--");
          this.echartData.xUnit.push(list[i]["年份"] + "年" || "");
        }
        for (let i = 0; i < this.echartData.data1.length; i++) {
          if (
            Number(this.echartData.data1[i]) === 0 ||
            this.echartData.data1[i] === ""
          ) {
            this.echartData.data1.splice(i, 1);
            this.echartData.data2.splice(i, 1);
            this.echartData.xUnit.splice(i, 1);
            i--;
          }
        }
        let totalNum = Number(this.echartData.data1.length) - 1;
        if (this.echartData.xUnit[0]) {
          this.economyTime.push(this.echartData.xUnit[0]);
        }
        if (this.echartData.xUnit[totalNum]) {
          this.economyTime.push(this.echartData.xUnit[totalNum]);
        }

        //人均可支配收入
        for (let i in list) {
          this.echartsData2.push({
            name: list[i]["年份"] + "年" || "",
            value: list[i]["人均可支配收入"] || "",
          });
        }
        for (let i = 0; i < this.echartsData2.length; i++) {
          if (
            Number(this.echartsData2[i].value) === 0 ||
            this.echartsData2[i].value === ""
          ) {
            this.echartsData2.splice(i, 1);
            i--;
          }
        }
        let totalNum2 = Number(this.echartsData2.length) - 1;
        if (this.echartsData2[0]) {
          this.economyTime2.push(this.echartsData2[0].name || "");
        }
        if (this.echartsData2[totalNum2]) {
          this.economyTime2.push(this.echartsData2[totalNum2].name || "");
        }

        //人均消费支出
        for (let i in list) {
          this.echartsData3.push({
            name: list[i]["年份"] + "年" || "",
            value: list[i]["人均消费支出"] || "",
          });
        }
        for (let i = 0; i < this.echartsData3.length; i++) {
          if (
            Number(this.echartsData3[i].value) === 0 ||
            this.echartsData3[i].value === ""
          ) {
            this.echartsData3.splice(i, 1);
            i--;
          }
        }
        let totalNum3 = Number(this.echartsData3.length) - 1;
        if (this.echartsData3[0]) {
          this.economyTime3.push(this.echartsData3[0].name || "");
        }
        if (this.echartsData3[totalNum3]) {
          this.economyTime3.push(this.echartsData3[totalNum3].name || "");
        }

        //消费品零售总额
        for (let i in list) {
          this.echartsData4.push({
            name: list[i]["年份"] + "年" || "",
            value: list[i]["消费品零售总额"] || "",
          });
        }
        for (let i = 0; i < this.echartsData4.length; i++) {
          if (
            Number(this.echartsData4[i].value) === 0 ||
            this.echartsData4[i].value === ""
          ) {
            this.echartsData4.splice(i, 1);
            i--;
          }
        }
        let totalNum4 = Number(this.echartsData4.length) - 1;
        if (this.echartsData4[0]) {
          this.economyTime4.push(this.echartsData4[0].name || "");
        }
        if (this.echartsData4[totalNum4]) {
          this.economyTime4.push(this.echartsData4[totalNum4].name || "");
        }
      }
      this.zongheLoadingStatus = false;
    },

    //综合经济--切换
    economyChange(ID) {
      this.economyID = ID;
    },
  },
  mounted() {
    let searchKey = this.$route.query.key;
    if (searchKey) {
      this.searchKey = searchKey;
    }
    //获取地址栏参数
    let getID = this.$route.query.id;
    if (getID) {
      this.cityID = getID;
    }
    //获取详情信息
    this.getDetail();
    //综合经济
    this.getEconomyData();

    this.scrollBox.addEventListener("scroll", this.handleScroll);
  },
  components: {
    offlineAnalysis,
    onlineAnalysis,
    SummaryCard,
    MenuSide,
    MapPanelLittle,
    BarLineChart,
    BarChart,
    DataNull,
    Business,
    Traffic,
  },
};
</script>

<style lang="less" scoped>
@import "../../assets/stylesheets/table.less";

.detail-main {
  display: flex;
  flex-wrap: wrap;

  .main-scroll {
    width: calc(100% - 200px);
    height: calc(100% - 101px);
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: auto;
    position: relative;
    background: #f6f6fa;
    transition: width 0.3s;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-content: flex-start;

    .map-size {
      width: 100%;
      height: 200px;
      background: #d7d7d7;
      z-index: 1;
    }

    .main-size {
      width: 1120px;
      min-width: 1120px;
      margin-top: -62px;
      position: relative;
      z-index: 99;
    }

    .content-item {
      width: 100%;
      height: auto;
      position: relative;
      margin: 20px 0 0 0;
      width: 100%;
      height: auto;
      background: #ffffff;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
      border-radius: 4px;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;

      .item-title {
        width: 100%;
        height: 56px;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        color: #000000;
        box-sizing: border-box;
        padding: 0 0 0 20px;
        border-bottom: 1px solid #e0e0e0;
      }

      .economy-size {
        width: 100%;
        height: 325px;
        display: flex;
        align-content: flex-start;
        flex-wrap: wrap;
        box-sizing: border-box;
        padding: 28px 30px 0 30px;
        position: relative;
        .economy-nav {
          width: 100%;
          display: flex;
          align-items: center;
          margin: 0 0 15px 0;

          .economy-nav-box {
            margin-right: 20px;
            font-size: 14px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.7);
            cursor: pointer;
          }

          .nav-choose {
            font-weight: 600;
            color: #3519fb;
          }
        }

        .charts-size {
          width: 100%;
          height: 237px;
          border-top: 1px solid #eeeeee;

          .charts-title {
            width: 100%;
            margin: 20px 0 15px 0;
            font-size: 14px;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.8);
          }

          .charts-box {
            width: 100%;
            height: calc(100% - 55px);
          }
        }
      }
      .content-wrap {
        width: 100%;
        height: 580px;
        padding: 30px;
        .content {
          height: 100%;
          border: 1px solid #eeeeee;
          display: flex;
          border-radius: 4px;
          overflow: hidden;
        }
      }
    }
    .anchor-box9 {
      margin-top: 20px;
    }
    .custom-item {
      flex-direction: column;
      margin-top: 20px;
      img {
        width: auto;
      }
    }
    .page-bottom {
      width: 100%;
      height: 60px;
    }
  }

  .main-scroll-change {
    width: calc(100% - 74px);
    transition: width 0.3s;
  }
}
</style>
