var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content" }, [
    _c(
      "div",
      { staticClass: "content-left" },
      [
        _c("MapPanel", {
          ref: "mapPanel",
          attrs: { legendsObj: _vm.legendsObj, ignoreFitChina: true },
          on: { loaded: _vm.handleMapLoaded, click: _vm.handleMapClick },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "content-right",
      },
      [_c("CustomData", { attrs: { dataList: _vm.dataList, rowNumber: 2 } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }