var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "bar-chart-size" },
    [
      _vm.echartData.length !== 0
        ? _c("div", {
            ref: "chartBox",
            staticClass: "chart-box",
            staticStyle: { width: "100%", height: "100%" },
          })
        : _vm._e(),
      _vm.echartData.length === 0 ? _c("dataNull") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }