<template>
  <!-- 折柱混合统计图 -->
  <div class="bar-chart-size">
    <div
      class="chart-box"
      ref="chartBox"
      style="width: 100%; height: 100%"
      v-if="echartData.length !== 0"
    ></div>
    <dataNull v-if="echartData.length === 0"></dataNull>
  </div>
</template>
<script>
import dataNull from "../chart/dataNull.vue";
// vue文件中引入echarts工具
let echarts = require("echarts");
// 以下的组件按需引入
require("echarts/lib/chart/bar");
require("echarts/lib/chart/line");
require("echarts/lib/component/tooltip"); // tooltip组件
require("echarts/lib/component/title"); // title组件
require("echarts/lib/component/legend"); // legend组件

export default {
  props: ["echartData", "barColor"],
  data() {
    return {
      //图表
      barChart: {},

      ySplitNumber: 4,
    };
  },
  computed: {},
  components: {
    dataNull,
  },
  watch: {
    echartData() {
      this.drawChart();
    },
  },
  mounted() {
    this.drawChart();
  },
  beforeDestroy() {
    window.addEventListener("resize", () => {
      this.myChart.resize();
    });
  },
  methods: {
    //计算最大值
    countMax(arr) {
      let max = 0;
      arr.forEach((el) => {
        if (!(el === undefined || el === "")) {
          if (max < Number(el)) {
            max = Number(el);
          }
        }
      });
      let maxint = Math.ceil(max / 9.5); //不让最高的值超过最上面的刻度
      let maxval = maxint * 10; //让显示的刻度是整数
      return maxval;
    },

    //计算最小值
    countMin(arr) {
      let min = 0;
      arr.forEach((el) => {
        if (!(el === undefined || el === "")) {
          if (min > Number(el)) {
            min = Number(el);
          }
        }
      });
      let minval = Math.floor(min);
      return minval;
    },

    //数据更新
    drawChart: function () {
      // if (this.echartData.length === 0) {
      //   return false;
      // }
      // 基于准备好的dom，初始化echarts实例
      this.myChart = echarts.init(this.$refs.chartBox);
      // 绘制图表
      this.myChart.setOption({
        tooltip: {
          show: true,
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: function (arg) {
            let showWord =
              "数额：" +
              arg[0].value +
              "亿元</br>" +
              "涨幅：" +
              arg[1].value +
              "%";
            return showWord;
          },
        },
        grid: {
          x: 0,
          x2: 5,
          y: 8,
          y2: 0,
          containLabel: true,
        },
        // legend: {
        //   x: "left", //可设定图例在左、右、居中
        //   y: "top",
        //   padding: [6, 0, 0, 168],
        //   data: [
        //     { name: "金额", icon: "circle" },
        //     { name: "涨幅", icon: "circle" },
        //   ],
        //   textStyle: {
        //     verticalAlign: "center",
        //     align: "left",
        //     padding: [4, 0, 0, 0],
        //   },
        // },
        xAxis: [
          {
            type: "category",
            data: this.echartData.xUnit,
            axisPointer: {
              type: "shadow",
            },
            //隐藏刻度线
            axisTick: {
              show: false,
            },
            //隐藏轴线
            axisLine: {
              show: false,
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#61677A", //这里是改变字体颜色
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "亿元",
            nameTextStyle: {
              color: "#61677A",
              padding: [0, 38, 5, 40],
            },
            // min: 0,
            // max: 300,
            // interval: maxValue / 5,
            // axisLabel: {
            //   formatter: "{value}",
            // },
            min: this.countMin(this.echartData.data1),
            max: this.countMax(this.echartData.data1),
            interval:
              (this.countMax(this.echartData.data1) -
                this.countMin(this.echartData.data1)) /
              this.ySplitNumber,
            //隐藏刻度线
            axisTick: {
              show: false,
            },
            //隐藏轴线
            axisLine: {
              show: false,
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#61677A", //这里是改变字体颜色
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: ["#F2F5FF"],
                width: 1,
                type: "solid",
              },
            },
          },
          {
            type: "value",
            name: "",
            nameTextStyle: {
              color: "#61677A",
              padding: [0, 0, 5, 38],
            },
            // min: 0,
            // max: 100,
            // interval: 20,
            // axisLabel: {
            //   formatter: "{value} °C",
            // },
            // splitNumber: 5, //分割成几段
            min: this.countMin(this.echartData.data2),
            max: this.countMax(this.echartData.data2),
            interval:
              (this.countMax(this.echartData.data2) -
                this.countMin(this.echartData.data2)) /
              this.ySplitNumber,
            //隐藏刻度线
            axisTick: {
              show: false,
            },
            //隐藏轴线
            axisLine: {
              show: false,
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#61677A", //这里是改变字体颜色
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: ["#f3f5f7"],
                width: 2,
                type: "solid",
              },
            },
          },
        ],
        series: [
          {
            data: this.echartData.data1,
            name: "数额",
            type: "bar",
            barWidth: 28,
            itemStyle: {
              normal: {
                color: this.barColor[0],
                //barBorderRadius: [5, 5, 0, 0],
              },
            },
          },
          {
            data: this.echartData.data2,
            name: "涨幅",
            type: "line",
            yAxisIndex: 1,
            itemStyle: {
              normal: {
                color: this.barColor[1],
                lineStyle: {
                  color: this.barColor[1],
                },
              },
            },
            areaStyle: {
              color: {
                //线性渐变
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(249,144,0,0.18)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(249,144,0,0)", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          },
        ],
      });
      setTimeout(() => {
        this.myChart.resize();
      }, 20);
      window.addEventListener("resize", () => {
        this.myChart.resize();
      });
    },
  },
};
</script>
<style lang="less">
.bar-chart-size {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .chart-box {
    width: 100%;
    height: 100%;
  }
}
</style>
